import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP} from '@ionic-native/http/ngx';

import {BackgroundMode} from '@ionic-native/background-mode/ngx';

// import {AngularFireModule} from '@angular/fire';
// import {AngularFirestoreModule} from '@angular/fire/firestore';
// import {AngularFireAuthModule} from '@angular/fire/auth';
//
// const firebaseConfig = {
//     apiKey: 'AIzaSyAZ7ELX5KrV_cJhoiV9c2GZpYPTkaRSntc',
//     authDomain: 'firehawk-permits.firebaseapp.com',
//     projectId: 'firehawk-permits',
//     storageBucket: 'firehawk-permits.appspot.com',
//     messagingSenderId: '446643191215',
//     appId: '1:446643191215:web:cc1ddd64841d682f01035a',
//     measurementId: 'G-0S49JXYWLM'
// };

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        // AngularFireModule.initializeApp(firebaseConfig),
        // AngularFirestoreModule,
        // AngularFireAuthModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        HTTP,
        BackgroundMode,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
